.nav {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
}

.nav__box {
    display: flex;
    position: relative;
    align-items: center;
}

.button__nav {
    margin-top: 2px;
    margin-left: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transform: rotate(180deg);
}

.button__false {
    transform: rotate(360deg);
}

.box__link {
    width: 154px;
    min-height: 60px;
    position: absolute;
    top: 50px;
    left: -50%;
    background: #383838;
    box-shadow: 0 4px 10px #D65EB6;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 0;
    box-sizing: border-box;
    z-index: 6;
}

.link {
    color: #FFFFFF;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
    transition: all 0.5s ease 0s;
}

.link:hover {
    box-shadow: 0 8px 18px rgba(218, 83, 177, 0.86);
}

@media screen and (max-width: 1200px) {
    .nav {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
    }

    .button__nav {
        margin-left: 10px;
    }

    .box__link {
        width: 80px;
        min-height: 35px;
        top: 35px;
        border-radius: 10px;
    }

    .link {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
    }

    .link:hover {
        box-shadow: 0 6px 14px rgba(218, 83, 177, 0.86);
    }
}

@media screen and (max-width: 770px) {
    .nav {
        font-size: 25px;
        line-height: 30px;
    }

    .button__nav {
        display: none;
    }

    .nav__footer {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 11px;
    }

    .link {
        display: none;
    }
}
