@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Regular.woff') format('woff');
  }
  

.about_us {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 64px 0 92px;
    box-sizing: border-box;
    background: #121212;
    border-style: solid;
    border-width: 0 0 2px;
    border-image: linear-gradient(to right, #000000, rgba(255, 255, 255, 0.56) 50%, #000000) 1;
}

.logo_title {
    margin: 5px 0 50px 0;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
}

.about_container_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    
    background: url(../../images/AboutUs/bg-logo.svg) center no-repeat;
    background-size: 21%;
}

.about_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    padding: 40px 5% 10px;   

    background: url(../../images/AboutUs/bg-logo.svg) center no-repeat;
    background-size: 21%;

    gap: 50px 0;
}

.about_title {
    max-width: 300px;
    margin: 0 0 25px 0;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.cardLeft {
    position: absolute;
    left: -35px;
    top: 45px;
    position: absolute;
}

.cardRight {
    position: absolute;
    right: -35px;
    top: 50px;
}

.about_subtitle {
    max-width: 215px;
    margin: 0;
    color: rgba(255, 255, 255, 0.60);
    font-family: 'Gilroy', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
    text-align: center;
}

.item {
    position: relative;
    flex-basis: 50%;
    /* Другие стили для элементов, например, размеры и отступы */
}
.item:nth-child(odd) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.item:nth-child(even) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.item:last-child {
display: none;
}

.about_container .item:nth-child(even) .about_title, .about_container .item:nth-child(even) .about_subtitle {
    text-align: right;
}

.about_container .item:nth-child(odd) .about_title, .about_container .item:nth-child(odd) .about_subtitle {
    text-align: left;
}

.lineLeftbig, .lineRightbig {
    position: absolute;
    top: 60px;
}

.lineLeft, .lineRight {
    display: none;
}

.second_div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottomCard {
    margin: 20px 0 10px 0;
}

.divider {
    width: 200px;
    height: 1px;
    background: linear-gradient(to right, #D366B9, #8CCED4);
    margin-bottom: 15px;
}

.container {
    flex-direction: column;
    display: flex;
    margin: 31px 0 0;
    gap: 30px;
    padding: 0 20px;
    box-sizing: border-box;
}

.text__box {
    max-width: 1400px;
    width: 100%;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text__box span {
    color: #BE34A3;
}

.text {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 16px;
}

.text:last-of-type {
    margin: 0;
}

@media screen and (max-width: 1400px) {
    .about_us {
        padding-bottom: 44px;
    }

    .container {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 770px) {
    .about_us {
        padding-top: 35px;
        padding-bottom: 26px;
    }

    .container {
        flex-direction: column-reverse;
        margin: 16px 0 0;
        gap: 15px;
    }

    .text {
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 20px;
    }

    .lineLeftbig, .lineRightbig {
        display: none;
    }
    
    .lineLeft, .lineRight {
        display: block;
        position: absolute;
        width: 100%;
        top: 60px;
    }
    .about_container {
        background: none;
    }
}

@media  (max-width: 650px)  {
    .logo_title {
        display: none;
    }
    .item {
        flex-basis: 100%;
        align-items: center;
    }
    .item:nth-child(odd) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .item:nth-child(even) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .lineLeft {
        left: 35px;
    }
    .lineRight {
        right: 35px;
    }
    .about_container .item:nth-child(even) .about_title, .about_container .item:nth-child(even) .about_subtitle {
        padding-right: 35px;
        text-align: right;
    }
    
    .about_container .item:nth-child(odd) .about_title, .about_container .item:nth-child(odd) .about_subtitle {
        padding-left: 35px;
        max-width: 250px;
        text-align: left;
    }

    .about_container .item:last-child .about_title, .about_container .item:last-child .about_subtitle {
        padding-left: 35px;
        max-width: 300px;
        text-align: left;
    }

    .about_container .item:last-child .lineRight {
        right: 0;
        left: 35px;
    }

    .about_container_wrapper, .about_container {
        background: none;
    }
    .about_container {
        padding: 0 0;   
    }
    .cardLeft {
        left: 0px;
    }
    
    .cardRight {
        right: 0px;
    }
    .second_div {
        display: none
    }
    .text__box {
        display: none;
    }
    .container {
        width: 100%;
    }
}

