.header__container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    background: rgba(0, 0, 0, 0);
    top: 0;
    z-index: 5;
}

.scrolled {
    background: #000000;
}

.header {
    max-width: 1328px;
    width: 100%;
    display: flex;
    padding: 19px 10px 0;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 64px;
    height: 123px;
    cursor: pointer;
}

.header__nav {
    height: 49px;
    display: flex;
    max-width: 892px;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px 0;
    border-image: linear-gradient(to right, #000000, rgba(255, 255, 255, 0.56) 50%, #000000) 1;
}

.box__nav {
    width: 100%;
    display: flex;
}

.container__nav {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
    z-index: 6;
}

.header__button {
    max-width: 220px;
    width: 100%;
    height: 50px;
    padding: 0 16px 0 19px;
    box-sizing: border-box;
    display: flex;
    border: none;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
    border-radius: 22px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    color: #222221;
    cursor: pointer;
    transition: box-shadow 0.5s ease;
}

.header__button:hover {
    box-shadow: 0 4px 16px rgba(218, 83, 177, 0.89);
}

.header__button_container {
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.button__box {
    max-width: 220px;
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 22px;
    margin-top: 12px;
    transition: box-shadow 0.5s ease;
}

.button__box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid #D65EB6;
    border-width: 0 0 2px;
    box-sizing: border-box;
    border-radius: 22px;
}

.button__box::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid #9EEAF1;
    border-width: 2px 0 0 2px;
    border-radius: 22px;
    box-sizing: border-box;
}

.button__box:hover {
    box-shadow: 0 4px 16px rgba(218, 83, 177, 0.89);
}

.button__flooded {
    padding: 0 0 0 6px;
    box-sizing: border-box;
    max-width: 119px;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text__flooded {
    width: 100%;
    font-weight: 700;
    padding-left: 6px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    color: #000000;
    display: flex;
    align-items: center;
}

.button__icon {
    width: 25px;
    height: 25px;
}

.button__text {
    width: 50%;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
}

.button__menu {
    display: none;
}

@media screen and (max-width: 1200px){
    .header {
        padding: 20px 53px 0 49px;
    }

    .logo {
        width: 45px;
        height: 87px;
    }
    .header__nav {
        padding: 0 25px 0 30px;
        border: none;
    }

    .header__button {
        max-width: 135px;
        width: 100%;
        height: 31px;
        font-size: 13px;
        line-height: 16px;
        padding: 0;
        justify-content: space-evenly;
    }

    .header__button:hover {
        box-shadow: 0 4px 14px rgba(218, 83, 177, 0.89);
    }

    .button__box {
        max-width: 150px;
        width: 100%;
        height: 31px;
    }

    .button__box:hover {
        box-shadow: 0 4px 14px rgba(218, 83, 177, 0.89);
    }

    .text__flooded {
        font-size: 11px;
        line-height: 12px;
        padding-left: 4px;
    }

    .button__icon {
        width: 16px;
        height: 16px;
    }

    .button__text {
        font-size: 12px;
        line-height: 12px;
    }

    .button__flooded {
        width: 60%;
    }

    .button__svg {
        width: 11px;
        height: 9px;
    }
}

@media screen and (max-width: 770px) {
    .header {
        padding: 13px 15px 0;
        align-items: start;
    }

    .button__box {
        margin-top: 12px;
    }

    .burger__menu {
        display: block;
        position: relative;
        margin-top: 15px;
        width: 24px;
        height: 21px;
        z-index: 2;
    }
    .burger__menu span {
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 3px;
        border-radius: 3px;
        left: 0;
        top: 9px;
        transition: all 0.3s ease 0s;
    }
    .burger__menu::before,
    .burger__menu::after {
        content: '';
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 3px;
        border-radius: 3px;
        left: 0;
        transition: all 0.3s ease 0s;
    }
    .burger__menu::before {
        top: 0;
    }
    .burger__menu::after {
        bottom: 0;
    }

    .burger__menu.active::before {
        transform: rotate(45deg);
        top: 9px;
    }

    .burger__menu.active::after {
        transform: rotate(-45deg);
        bottom: 9px;
    }

    .burger__menu.active span {
        transform: scale(0);
    }

    .header__nav {
        overflow: auto;
        position: fixed;
        flex-direction: column;
        top: -100%;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: #000000;
        z-index: 2;
        transition: all 0.4s ease 0s;
    }

    .box__nav {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }

    .box__nav::before,
    .box__nav::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    .box__nav::after {
        background: url("../../images/header/Ellipse2__320px.svg") no-repeat left top;
    }

    .box__nav::before {
        background: url("../../images/header/Ellipse1__320px.svg") no-repeat right bottom;
    }

    .container__nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
    }

    .header__nav.active {
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        top: 0;
        transition: all 0.6s ease 0s;
        padding: 0;
    }
}
