.apartment {
    border-radius: 8px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 4;
    opacity: 1;
    transition: all 0.7s ease 0s;
}

.true {
    background: radial-gradient(61.48% 61.48% at 50% 61.48%, #AA624F 0%, rgba(170, 98, 79, 0) 100%), #1E1E1E;
    border: 1px solid rgba(170, 98, 79, 0.3);
}

.false {
    background: radial-gradient(61.48% 61.48% at 50% 61.48%, #40403F 0%, rgba(64, 64, 63, 0) 100%), #1E1E1E;
    border: 1px solid rgba(94, 94, 93, 0.3);
}

.name {
    color: #9BF0F3;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}

.name__color {
    color: white;
}

.price {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0;
}

.occupied {
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    color: #AA624F;
    margin: 0 0 6px;
}

.buy {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #222221;
    background: #56AA4F;
    border-radius: 9px;
    cursor: pointer;
}

.buy__color {
    background: linear-gradient(180deg, rgba(217, 88, 179, 0.9) 0%, rgba(159, 231, 240, 0.9) 100%),
    conic-gradient(from 180deg at 48.5% 50%, #B8C9D3 -28.12deg, #FBFBFD 26.25deg, #C8D4DA 88.12deg, #FFFFFF 156.58deg, #AEC0CE 191.74deg, #E3E9EE 237.13deg, #FAFBFC 255.19deg, #D6DFE6 310.11deg, #B8C9D3 331.88deg, #FBFBFD 386.25deg);
}

.info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.name__0,
.name__24,
.name__22,
.name__20,
.name__18,
.name__16,
.name__15,
.name__1,
.name__3,
.name__5,
.name__7,
.name__9,
.name__41,
.name__40,
.name__43,
.name__47,
.name__34,
.name__32,
.name__30,
.name__28,
.name__26,
.name__25,
.name__49,
.name__45 {
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 3px;
}

.name__42,
.name__44,
.name__46,
.name__48,
.name__37,
.name__33,
.name__31,
.name__29,
.name__27,
.name__2,
.name__4,
.name__6,
.name__8,
.name__12,
.name__23,
.name__21,
.name__19,
.name__17,
.name__39,
.name__38,
.name__36,
.name__35,
.name__10,
.name__11,
.name__13,
.name__14 {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 3px;
}

.occupied__0,
.occupied__24,
.occupied__22,
.occupied__20,
.occupied__18,
.occupied__16,
.occupied__15,
.occupied__34,
.occupied__32,
.occupied__30,
.occupied__28,
.occupied__26,
.occupied__25,
.occupied__1,
.occupied__3,
.occupied__5,
.occupied__7,
.occupied__9,
.occupied__40,
.occupied__49,
.occupied__41,
.occupied__43,
.occupied__45,
.occupied__47 {
    font-size: 8px;
    line-height: 10px;
}

.occupied__42,
.occupied__44,
.occupied__46,
.occupied__48,
.occupied__37,
.occupied__33,
.occupied__31,
.occupied__29,
.occupied__27,
.occupied__2,
.occupied__4,
.occupied__6,
.occupied__8,
.occupied__12,
.occupied__23,
.occupied__21,
.occupied__19,
.occupied__17,
.occupied__39,
.occupied__38,
.occupied__36,
.occupied__35,
.occupied__10,
.occupied__11,
.occupied__13,
.occupied__14 {
    font-size: 12px;
    line-height: 14px;
}

.buy__39,
.buy__38,
.buy__36,
.buy__35,
.buy__10,
.buy__11,
.buy__13,
.buy__14 {
    width: 58px;
    border-radius: 8px 8px 0 0;
}

.apartment__0 {
    top: 14px;
    right: 515px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__1 {
    top: 14px;
    right: 435px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__3 {
    top: 14px;
    right: 355px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__5 {
    top: 14px;
    right: 275px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__7 {
    top: 14px;
    right: 195px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__9 {
    top: 14px;
    right: 115px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__40 {
    top: 14px;
    left: 115px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__49 {
    top: 14px;
    left: 515px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__47 {
    top: 14px;
    left: 435px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__45 {
    top: 14px;
    left: 355px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__43 {
    top: 14px;
    left: 275px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__41 {
    top: 14px;
    left: 195px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__34 {
    bottom: 14px;
    left: 115px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__25 {
    bottom: 14px;
    left: 515px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__26 {
    bottom: 14px;
    left: 435px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__28 {
    bottom: 14px;
    left: 355px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__30 {
    bottom: 14px;
    left: 275px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__32 {
    bottom: 14px;
    left: 195px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__15 {
    bottom: 14px;
    right: 115px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__24 {
    bottom: 14px;
    right: 515px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__22 {
    bottom: 14px;
    right: 435px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__20 {
    bottom: 14px;
    right: 355px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__18 {
    bottom: 14px;
    right: 275px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__16 {
    bottom: 14px;
    right: 195px;
    width: 65px;
    height: 83px;
    padding-top: 20px;
}

.apartment__14 {
    right: 14px;
    top: 470px;
    width: 84px;
    height: 75px;
    padding-top: 16px;
}

.apartment__13 {
    right: 14px;
    top: 380px;
    width: 84px;
    height: 75px;
    padding-top: 16px;
}

.apartment__11 {
    right: 14px;
    top: 290px;
    width: 84px;
    height: 75px;
    padding-top: 16px;
}

.apartment__10 {
    right: 14px;
    top: 200px;
    width: 84px;
    height: 75px;
    padding-top: 16px;
}

.apartment__35 {
    left: 14px;
    top: 470px;
    width: 84px;
    height: 75px;
    padding-top: 16px;
}

.apartment__36 {
    left: 14px;
    top: 380px;
    width: 84px;
    height: 75px;
    padding-top: 16px;
}

.apartment__38 {
    left: 14px;
    top: 290px;
    width: 84px;
    height: 75px;
    padding-top: 16px;
}

.apartment__39 {
    left: 14px;
    top: 200px;
    width: 84px;
    height: 75px;
    padding-top: 16px;
}

.apartment__48 {
    left: 480px;
    top: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__46 {
    left: 390px;
    top: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__44 {
    left: 300px;
    top: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__42 {
    left: 210px;
    top: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}


.apartment__33 {
    left: 220px;
    bottom: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__31 {
    left: 310px;
    bottom: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__29 {
    left: 400px;
    bottom: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__27 {
    left: 480px;
    bottom: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__33 {
    left: 210px;
    bottom: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__31 {
    left: 300px;
    bottom: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__29 {
    left: 390px;
    bottom: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__37 {
    left: 210px;
    bottom: 340px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__12 {
    right: 210px;
    bottom: 340px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__2 {
    right: 480px;
    top: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__4 {
    right: 390px;
    top: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__6 {
    right: 300px;
    top: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__8 {
    right: 210px;
    top: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__23 {
    right: 480px;
    bottom: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__21 {
    right: 390px;
    bottom: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__19 {
    right: 300px;
    bottom: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

.apartment__17 {
    right: 210px;
    bottom: 210px;
    width: 75px;
    height: 67px;
    padding-top: 6px;
}

@media screen and (max-width: 1600px) {
    .price {
        font-size: 6px;
        line-height: 7px;
    }

    .occupied {
        font-size: 6px;
        line-height: 7px;
        margin-bottom: 3px;
    }

    .buy {
        height: 12px;
        font-size: 6px;
        line-height: 7px;
        border-radius: 4px;
    }

    .name__0,
    .name__24,
    .name__22,
    .name__20,
    .name__18,
    .name__16,
    .name__15,
    .name__1,
    .name__3,
    .name__5,
    .name__7,
    .name__9,
    .name__41,
    .name__40,
    .name__43,
    .name__47,
    .name__34,
    .name__32,
    .name__30,
    .name__28,
    .name__26,
    .name__25,
    .name__49,
    .name__45 {
        font-size: 4px;
        line-height: 5px;
    }

    .name__42,
    .name__44,
    .name__46,
    .name__48,
    .name__37,
    .name__33,
    .name__31,
    .name__29,
    .name__27,
    .name__2,
    .name__4,
    .name__6,
    .name__8,
    .name__12,
    .name__23,
    .name__21,
    .name__19,
    .name__17,
    .name__39,
    .name__38,
    .name__36,
    .name__35,
    .name__10,
    .name__11,
    .name__13,
    .name__14 {
        font-size: 5px;
        line-height: 6px;
    }

    .occupied__0,
    .occupied__24,
    .occupied__22,
    .occupied__20,
    .occupied__18,
    .occupied__16,
    .occupied__15,
    .occupied__34,
    .occupied__32,
    .occupied__30,
    .occupied__28,
    .occupied__26,
    .occupied__25,
    .occupied__1,
    .occupied__3,
    .occupied__5,
    .occupied__7,
    .occupied__9,
    .occupied__40,
    .occupied__49,
    .occupied__41,
    .occupied__43,
    .occupied__45,
    .occupied__47 {
        font-size: 4px;
        line-height: 5px;
    }

    .occupied__42,
    .occupied__44,
    .occupied__46,
    .occupied__48,
    .occupied__37,
    .occupied__33,
    .occupied__31,
    .occupied__29,
    .occupied__27,
    .occupied__2,
    .occupied__4,
    .occupied__6,
    .occupied__8,
    .occupied__12,
    .occupied__23,
    .occupied__21,
    .occupied__19,
    .occupied__17,
    .occupied__39,
    .occupied__38,
    .occupied__36,
    .occupied__35,
    .occupied__10,
    .occupied__11,
    .occupied__13,
    .occupied__14 {
        font-size: 6px;
        line-height: 7px;
    }

    .buy__39,
    .buy__38,
    .buy__36,
    .buy__35,
    .buy__10,
    .buy__11,
    .buy__13,
    .buy__14 {
        width: 28px;
        border-radius: 4px 4px 0 0;
    }

    .apartment__0 {
        top: 7px;
        right: 250px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__1 {
        top: 7px;
        right: 210px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__3 {
        top: 7px;
        right: 170px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__5 {
        top: 7px;
        right: 130px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__7 {
        top: 7px;
        right: 90px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__9 {
        top: 7px;
        right: 50px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__40 {
        top: 7px;
        left: 50px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__49 {
        top: 7px;
        left: 250px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__47 {
        top: 7px;
        left: 210px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__45 {
        top: 7px;
        left: 170px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__43 {
        top: 7px;
        left: 130px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__41 {
        top: 7px;
        left: 90px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__34 {
        bottom: 7px;
        left: 50px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__25 {
        bottom: 7px;
        left: 250px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__26 {
        bottom: 7px;
        left: 210px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__28 {
        bottom: 7px;
        left: 170px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__30 {
        bottom: 7px;
        left: 130px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__32 {
        bottom: 7px;
        left: 90px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__15 {
        bottom: 7px;
        right: 50px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__24 {
        bottom: 7px;
        right: 250px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__22 {
        bottom: 7px;
        right: 210px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__20 {
        bottom: 7px;
        right: 170px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__18 {
        bottom: 7px;
        right: 130px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__16 {
        bottom: 7px;
        right: 90px;
        width: 31px;
        height: 40px;
        padding-top: 8px;
    }

    .apartment__14 {
        right: 7px;
        top: 225px;
        width: 40px;
        height: 36px;
        padding-top: 6px;
    }

    .apartment__13 {
        right: 7px;
        top: 180px;
        width: 40px;
        height: 36px;
        padding-top: 6px;
    }

    .apartment__11 {
        right: 7px;
        top: 135px;
        width: 40px;
        height: 36px;
        padding-top: 6px;
    }

    .apartment__10 {
        right: 7px;
        top: 90px;
        width: 40px;
        height: 36px;
        padding-top: 6px;
    }

    .apartment__35 {
        left: 7px;
        top: 225px;
        width: 40px;
        height: 36px;
        padding-top: 6px;
    }

    .apartment__36 {
        left: 7px;
        top: 180px;
        width: 40px;
        height: 36px;
        padding-top: 6px;
    }

    .apartment__38 {
        left: 7px;
        top: 135px;
        width: 40px;
        height: 36px;
        padding-top: 6px;
    }

    .apartment__39 {
        left: 7px;
        top: 90px;
        width: 40px;
        height: 36px;
        padding-top: 6px;
    }

    .apartment__48 {
        left: 235px;
        top: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__46 {
        left: 190px;
        top: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__44 {
        left: 145px;
        top: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__42 {
        left: 100px;
        top: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__33 {
        left: 100px;
        bottom: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__31 {
        left: 150px;
        bottom: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__29 {
        left: 400px;
        bottom: 210px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__27 {
        left: 235px;
        bottom: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__33 {
        left: 100px;
        bottom: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__31 {
        left: 145px;
        bottom: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__29 {
        left: 190px;
        bottom: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__37 {
        left: 100px;
        bottom: 160px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__12 {
        right: 100px;
        bottom: 160px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__2 {
        right: 235px;
        top: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__4 {
        right: 190px;
        top: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__6 {
        right: 145px;
        top: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__8 {
        right: 100px;
        top: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__23 {
        right: 235px;
        bottom: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__21 {
        right: 190px;
        bottom: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__19 {
        right: 145px;
        bottom: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }

    .apartment__17 {
        right: 100px;
        bottom: 100px;
        width: 36px;
        height: 32px;
        padding-top: 3px;
    }
}
