.container {
    width: 100%;
    padding: 77px 20px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border-style: solid;
    border-width: 0 0 2px;
    border-image: linear-gradient(to right, #000000, rgba(255, 255, 255, 0.56) 50%, #000000) 1;
}

.twitter_box {
    margin-top: 33px;
    border-radius: 14px;
    max-width: 1400px;
    width: 100%;
    max-height: 1000px;
    overflow-y: scroll;
    scrollbar-width: thin;
    -ms-overflow-style: none;
    border: 2px solid rgb(47, 51, 54);
    box-sizing: border-box;
}

@media screen and (max-width: 1400px) {
    .container {
        padding: 50px 20px;
    }
}

@media screen and (max-width: 600px) {
    .container {
        padding: 30px 0;
    }
}

