.up {
    position: fixed;
    bottom: 20px;
    right: 60px;
    width: 76px;
    height: 81px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
    border-radius: 22px;
    text-decoration: none;
    transition: box-shadow 0.5s ease;
    border: none;
    z-index: 7;
    cursor: pointer;
}

.up:hover {
    transition: box-shadow 0.5s ease;
    box-shadow: 0 4px 14px rgba(218, 83, 177, 0.89);
}

.img {
    margin-bottom: 8px;
}

.text {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    color: #222221;
}

@media screen and (max-width: 1200px) {
    .up {
        bottom: 20px;
        right: 30px;
        width: 60px;
        height: 60px;
        border-radius: 14px;
    }
    .img {
        width: 22px;
        height: 22px;
        margin-bottom: 2px;
    }

    .text {
        font-size: 20px;
        line-height: 20px;
    }

}

@media screen and (max-width: 770px) {
    .up {
        width: 40px;
        height: 40px;
        right: 15px;
        border-radius: 10px;
    }

    .img {
        width: 14px;
        height: 14px;
        margin-bottom: 0;
    }

    .text {
        font-size: 14px;
        line-height: 16px;
    }
}
