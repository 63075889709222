.apartment {
    max-width: 1395px;
    width: 100%;
    height: 750px;
    position: relative;
    background: #222221;
    border: 4px dashed rgb(94, 94, 93);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.apartment:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../../../images/Shop/apartment__background.webp");
}

.apartment__container {
    max-width: 1157px;
    width: 100%;
    height: 530px;
    background: #1E1E1E;
    border-radius: 19px;
    border: 3px solid rgba(94, 94, 93, 0.3);
    z-index: 3;
    position: relative;
}

.apartment__container:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(61.48% 61.48% at 50% 61.48%, #40403F 0%, rgba(64, 64, 63, 0) 100%);
}

.center__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.left__container {
    max-width: 386px;
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    background: #222221;
    border: 3px solid rgba(94, 94, 93, 0.3);
    border-radius: 12px;
    z-index: 3;
}

.left__container:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../../../images/Shop/apartment__background.webp");
}

.right__container {
    max-width: 386px;
    width: 100%;
    height: 350px;
    position: relative;
    background: #222221;
    border: 3px solid rgba(94, 94, 93, 0.3);
    z-index: 2;
    border-radius: 12px;
}

.right__container:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../../../images/Shop/apartment__background.webp");
}

.right__container:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../../../images/Shop/apartment__background_center_rigth.webp") no-repeat center;
    z-index: 2;
}

.left__container_center {
    max-width: 278px;
    width: 100%;
    height: 101px;
    background: url("../../../images/Shop/apartment__background_center_left.webp");
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 14px;
    box-sizing: border-box;
}

.left__container_center:after {
    content: '';
    position: absolute;
    width: 13px;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    background: #353534;
    border-radius: 11px 0 0 11px;
}

.elevator {
    max-width: 81px;
    width: 100%;
    height: 84px;
    background: #40403F;
    border: 2px solid #5E5E5D;
    border-radius: 6px;
}

.container_center {
    max-width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.center__text {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #222221;
    padding: 3px 5px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: center;
}

.green {
    background: #56AA4F;
}

.beige {
    background: rgba(235, 241, 169, 0.8);
}

.red {
    color: #AA624F;
    background: #222221;
}

.floor {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 62px;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-transform: uppercase;
    color: #AA624F;
    background: #222221;
    border-radius: 8px;
}

.top__box_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.floor__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}

.sector__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sector {
    height: 85px;
    font-style: normal;
    font-weight: 700;
    font-size: 84px;
    line-height: 101px;
    text-transform: uppercase;
    color: rgba(235, 241, 169, 0.8);
}

.bottom__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    margin-top: 62px;
}

@media screen and (max-width: 1600px) {
    .apartment {
        width: 663px;
        height: 355px;
        border-width: 2px;
        padding: 0 56px;
        box-sizing: border-box;
    }

    .apartment__container {
        width: 551px;
        height: 244px;
    }

    .left__container {
        width: 183px;
        height: 166px;
    }

    .right__container {
        width: 183px;
        height: 166px;
    }

    .elevator {
        width: 39px;
        height: 40px;
    }

    .left__container_center {
        height: 48px;
        width: 132px;
        background: url("../../../images/Shop/apartment__background_center_left_tiblet.webp") no-repeat;
        padding-right: 8px;
    }

    .left__container_center:after {
        height: 100%;
        width: 6px;
        border-radius: 5px 0 0 5px;
    }

    .right__container:before {
        background: url("../../../images/Shop/apartment__background_center_rigth_tiblet.webp") no-repeat center;
    }

    .floor__container {
        gap: 3px;
    }

    .center__text {
        font-size: 6px;
        line-height: 7px;
        padding: 1px 3px;
        border-radius: 2px;
    }

    .floor {
        width: 23px;
        height: 29px;
        font-size: 18px;
        line-height: 22px;
        border-radius: 3px;
    }

    .sector {
        height: 42px;
        font-size: 40px;
        line-height: 48px;
    }

    .bottom__box {
        margin-top: 29px;
        gap: 3px;
    }

    .top__box_center {
        gap: 8px;
    }
}
