.features {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 84px 10px 103px;
    box-sizing: border-box;
    align-items: center;
    border-style: solid;
    border-width: 0 0 2px;
    position: relative;
    border-image: linear-gradient(to right, #000000, rgba(255, 255, 255, 0.56) 50%, #000000) 1;
}

.features:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: url("../../images/Roadmap/roamap__background.svg") no-repeat right bottom;
    transform: rotate(180deg);
    opacity: 0.5;
}

.features__box {
    width: 100%;
    max-width: 1472px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.features__container {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.features__text {
    max-width: 1270px;
    margin: 0 0 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.6;
}

.container {
    width: 100%;
    padding: 40px 38px 45px;
    box-sizing: border-box;
    background: rgba(23, 23, 23, 0.97);
    border: 1px solid #2C2C2C;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 53px;
}

.container__accordion {
    width: 656px;
    height: 474px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    overflow-x: auto;
    border-radius: 22px;
    padding-right: 50px;
    box-sizing: border-box;
}

.accordion {
    width: 100%;
    height: 100%;
    border-radius: 22px;
}

.container__img {
    width: 656px;
    height: 474px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.features__img {
    max-width: 656px;
    max-height: 474px;
    object-fit: fill;
    border-radius: 22px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
    border-radius: 22px;
}

::-webkit-scrollbar {
    background: #2C2C2C;
    border-radius: 100px;
    width: 12px;
}

@media screen and (max-width: 1460px) {
    .container__accordion {
        width: 100%;
        max-width: 656px;
    }
}

@media screen and (max-width: 1400px) {
    .features {
        padding: 50px 0;
    }

    .features:after {
        opacity: 0.2;
    }


    .features__box {
        max-width: 710px;
        width: 100%;
    }

    .features__container {
        margin-top: 21px;
    }

    .features__text {
        max-width: 674px;
        margin: 0 0 21px;
    }

    .container {
        width: 100%;
        padding: 22px;
        gap: 8px;
        align-items: flex-start;
    }

    .container__accordion {
        width: 322px;
        height: 467px;
        padding-right: 8px;
    }

    .container__img {
        width: 324px;
        height: 234px;
    }

    .features__img {
        max-width: 324px;
        max-height: 234px;
    }
    ::-webkit-scrollbar {
        width: 8px;
    }
}

@media screen and (max-width: 770px) {
    .features {
        padding: 26px 14px 30px;
    }

    .features__container {
        margin-top: 12px;
    }

    .features__text {
        max-width: 284px;
        margin: 0 0 31px;
    }

    .container {
        width: 100%;
        padding: 8px 0 22px 9px;
        gap: 20px;
        flex-direction: column-reverse;
    }

    .container__accordion {
        width: 270px;
        height: 467px;
        padding-right: 4px;
    }

    .container__img {
        width: 270px;
        height: 196px;
        padding-right: 9px;
        box-sizing: border-box;
    }

    .features__img {
        max-width: 270px;
        max-height: 196px;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }
}
