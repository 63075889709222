.container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 70px;
    position: relative;
}

.container__img {
    width: 520px;
    height: 506px;
    border-radius: 22px;
}

.container__img li button {
    width: 13px;
    height: 13px;
    border-radius: 100%;
    margin-top: -30px;
    position: relative;
}

.container__img li button:before {
    content: '';
    color: rgba(0, 0, 0, 0);
}

.container__img li button {
    background: linear-gradient(89.35deg, #9BF0F3 0.56%, #DA53B1 102.39%);
}

.img__box {
    width: 520px;
    height: 506px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.img__box img {
    max-width: 520px;
    max-height: 506px;
    object-fit: fill;
    padding: 0 3px;
    box-sizing: border-box;
    border-radius: 22px;
}

.box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.title__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
    border-bottom: 1px solid #5E5E5D;
}

.title {
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 42px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0;
    padding-bottom: 35px;
    box-sizing: border-box;
}

.title span {
    background: linear-gradient(89.35deg, #9BF0F3 0.56%, #DA53B1 102.39%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text__box {
    display: flex;
    flex-direction: column;
    max-width: 774px;
}

.subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 20px 0 10px;
}

.subtitle__text_title {
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    font-weight: bold;
}

.subtitle__text {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
}

.subtitle__text p {
    margin: 0;
}

.subtitle__text a {
    text-decoration: none;
}

.button__box {
    width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
}

.price__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    color: #FFFFFF;
}

.price__container {
    display: flex;
    gap: 9px;
}

.price__number {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.price__info {
    max-width: 72px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
}

.button {
    width: 266px;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
    border-radius: 22px;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 26px;
    color: #222221;
    margin-top: 30px;
    border: none;
    cursor: pointer;
    transition: all 0.4s ease;
    padding: 0;
    text-decoration: none;
}

.button:hover {
    transition: all 0.4s ease;
    box-shadow: 0 4px 16px rgba(218, 83, 177, 0.89);
}

.button__img {
    width: 31px;
    height: 31px;
    margin-right: 8px;
}

.button__icon {
    width: 22px;
    height: 22px;
    margin-left: 8px;
    transform: rotate(180deg);
}

@media screen and (max-width: 1500px) {
    .container {
        max-width: 613px;
        flex-direction: column;
        gap: 14px;
    }

    .subtitle__text {
        font-size: 18px;
        line-height: 18px;
    }

    .title {
        padding-bottom: 14px;
    }

    .container__img {
        width: 613px;
        height: 337px;
    }

    .img__box {
        width: 613px;
        height: 337px;
    }

    .img__box img {
        max-width: 613px;
        max-height: 337px;
    }

    .button {
        margin-top: 14px;
    }
}

@media screen and (max-width: 770px) {
    .container {
        max-width: 311px;
        gap: 8px;
    }

    .container__img {
        width: 270px;
        height: 196px;
        margin-bottom: 0;
    }

    .img__box {
        width: 270px;
        height: 196px
    }

    .img__box img {
        max-width: 270px;
        max-height: 196px
    }

    .title__box {
        gap: 5px;
    }

    .title {
        font-size: 21px;
        line-height: 27px;
        padding-bottom: 6px;
    }

    .text__box {
        display: flex;
        flex-direction: column;
        max-width: 311px;
        margin-top: 0;
    }

    .subtitle {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    .subtitle__text_title {
        font-size: 12px;
        line-height: 14px;
    }

    .subtitle__text {
        font-size: 10px;
        line-height: 12px;
    }

    .button__box {
        width: 266px;
        margin-top: 5px;
        margin-bottom: 0;
    }

    .price {
        font-size: 22px;
        line-height: 27px;
    }

    .button {
        margin-top: 8px;
    }
}

