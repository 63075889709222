.card {
    max-width: 475px;
    width: 100%;
    height: 154px;
    padding: 20px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    background: #191919;
    border-radius: 3px;
}

.card_craft {
    max-width: 270px;
    height: 265px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.text_box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
}

.title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
}

.text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
}

.img {
    max-width: 61px;
}

@media screen and (max-width: 1340px) {
    .card {
        max-width: 325px;
    }

    .card_craft,
    .card_adaptive {
        width: 325px;
        height: 225px;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        text-align: left;
    }

    .title {
        font-size: 16px;
        line-height: 18px;
    }

    .text {
        font-size: 16px;
        line-height: 18px;
    }
}

@media screen and (max-width: 700px) {
    .card {
        max-width: 290px;
        padding: 10px 20px;
    }

    .card_craft,
    .card_adaptive {
        width: 290px;
    }

    .img {
        max-width: 40px;
    }
}
