.flats {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../../images/Roadmap/roamap__background.svg") no-repeat right bottom;
    position: relative;
    border-style: solid;
    border-width: 0 0 2px;
    border-image: linear-gradient(to right, #000000, rgba(255, 255, 255, 0.56) 50%, #000000) 1;
}

.flats:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("../../images/Roadmap/roamap__background.svg") no-repeat right top;
    transform: rotate(180deg);
}

.flats:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    right: 0;
    background: url("../../images/Flats/flats.webp") bottom;
}

.container {
    max-width: 1506px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 83px 0 100px;
    box-sizing: border-box;
    z-index: 2;
}

.flats__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    margin: 31px 0 0;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nav__container {
    margin-top: 39px;
    width: 1000px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav {
    width: 233px;
    height: 54px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: relative;
    opacity: 1;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}

.nav:hover {
    transition: all 0.4s ease;
    box-shadow: 0 4px 16px rgba(218, 83, 177, 0.89);
}

.nav::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: solid #D65EB6;
    border-width: 0 0 2px 2px;
    border-radius: 22px;
}

.nav::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: solid #9EEAF1;
    border-width: 2px 2px 0 0;
    border-radius: 22px;
}

.active {
    border: none;
    transition: all 0.3s ease 0s;
    opacity: 1;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
    color: #222221;
}

.box__flats {
    margin-top: 30px;
    color: white;
    width: 1506px;
    height: 671px;
    background: url("../../images/Flats/flats__background.svg") no-repeat center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button {
    border: none;
    background: rgba(0, 0, 0, 0);
    margin-bottom: 90px;
    margin-left: 15px;
    cursor: pointer;
    border-radius: 50%;
    padding: 0;
}

.button:last-of-type {
    margin-left: 0;
    margin-right: 15px;
}

.back__img {
    width: 20px;
    height: 30px;
    transform: rotate(180deg);
}

.forward__img {
    width: 20px;
    height: 30px;
}

.nav__768px {
    display: none;
}

@media screen and (max-width: 1500px) {
    .flats {
        background: none;
    }

    .box__flats {
        margin-top: 30px;
        width: 726px;
        height: 941px;
        background: url("../../images/Flats/flats__background_tablet.svg") no-repeat center;
    }

    .container {
        max-width: 726px;
        padding: 50px 0;
    }

    .flats__subtitle {
        margin: 24px 0 0;
    }

    .nav__container {
        margin-top: 29px;
        width: 668px;
    }

    .nav {
        width: 156px;
        height: 54px;
    }

    .center__container {
        width: 613px;
    }

    .button {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 770px) {
    .box__flats {
        width: 311px;
        height: 636px;
        justify-content: center;
        background: url("../../images/Flats/flats__background_mobile.svg") no-repeat center;
    }

    .flats__subtitle {
        margin: 11px 0 0;
    }

    .container {
        max-width: 290px;
        padding: 30px 0;
    }

    .nav__container {
        display: none;
    }

    .nav__768px {
        width: 70%;
        margin-top: 21px;
        display: flex;
    }

    .center__container {
        padding-left: 3px;
        box-sizing: border-box;
        width: 270px;
    }

    .button {
        margin-bottom: 12px;
        margin-left: 0;
    }

    .button:last-of-type {
        margin-left: 0;
        margin-right: 0;
    }

    .back__img {
        width: 14px;
        height: 20px;
        transform: rotate(180deg);
    }

    .forward__img {
        width: 14px;
        height: 20px;
    }
}
