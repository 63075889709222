.box {
    max-width: 1400px;
    width: 100%;
    height: 500px;
    border-radius: 22px;
    overflow: hidden;
    border: 1px solid #929292;
}

.youtube {
    height: 100%;
    width: 100%;
}

.youtube iframe {
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .box {
        height: 400px;
    }
}

@media screen and (max-width: 770px) {
    .box {
        height: 310px;
    }
}

@media screen and (max-width: 510px) {
    .box {
        height: 210px;
    }
}
