.shop {
    width: 100%;
    padding: 107px 20px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border-style: solid;
    border-width: 0 0 2px;
    border-image: linear-gradient(to right, #000000, rgba(255, 255, 255, 0.56) 50%, #000000) 1;
}

.shop__container {
    color: #FFFFFF;
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 39px;
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav__container {
    height: 55px;
    width: 840px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;
}

.button__arrow {
    position: absolute;
    left: 249px;
    top: 13px;
}

.button__arrow2 {
    position: absolute;
    right: 249px;
    top: 13px;
}

.nav {
    height: 54px;
    width: 233px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: relative;
}

.cursor {
    cursor: pointer;
    transition: all 0.6s ease;
}

.cursor:hover {
    transition: all 0.6s ease;
    box-shadow: 0 4px 16px rgba(218, 83, 177, 0.89);
}

.nav::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: solid #D65EB6;
    border-width: 0 0 2px 2px;
    border-radius: 22px;
}

.nav::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: solid #9EEAF1;
    border-width: 2px 2px 0 0;
    border-radius: 22px;
}

.nav__active {
    transition: all 0.3s ease;
    opacity: 1;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
    color: #222221;
}

.button__container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
}

.button {
    width: 233px;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    padding: 0 16px;
    border: none;
    box-sizing: border-box;
    position: relative;
    border-radius: 22px;
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s ease 0s;
}
.button:hover {
    transition: all 0.6s ease;
    box-shadow: 0 4px 16px rgba(218, 83, 177, 0.89);
}

.button__back {
    text-align: right;
}

.button__next {
    text-align: left;
}

.arrow {
    width: 28px;
    height: 20px;
}

.arrow__back {
    transform: rotate(180deg);
}

.disabled {
    opacity: 0;
    transition: all 0.3s ease 0s;
}

.button:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: solid #9EEAF1;
    border-width: 2px 2px 0 0;
    border-radius: 22px;
}

.button:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: solid #D65EB6;
    border-width: 0 0 2px 2px;
    border-radius: 22px;
}

.nav__768px {
    display: none;
}

.reminder {
    position: absolute;
    background: rgb(89, 88, 88);
}

@media screen and (max-width: 1650px) {
    .shop {
        padding: 57px 10px 50px;
    }
    .shop__container {
        margin-top: 31px;
    }
    .button__container {
        max-width: 661px;
        justify-content: space-between;
    }

    .button {
        width: 186px;
    }
    .nav {
        width: 186px;
    }
    .nav__container {
        max-width: 668px;
        margin-bottom: 20px;
    }
    .button__arrow {
        width: 29px;
        height: 20px;
        position: absolute;
        left: 200px;
        top: 16px;
    }

    .button__arrow2 {
        width: 29px;
        height: 20px;
        position: absolute;
        right: 200px;
        top: 16px;
    }
}

@media screen and (max-width: 768px) {

    .nav__container {
        display: none;
    }

    .shop__container {
        margin-top: 20px;
        max-width: 460px;
        justify-content: center;
    }

    .shop {
        padding: 30px 0;
    }

    .nav__768px {
        width: 290px;
        display: flex;
        margin-bottom: 10px;
    }

    .container {
        justify-content: start;
        overflow-x: auto;
        width: 100%;
        padding-bottom: 18px;
        box-sizing: border-box;
    }

    .button__container {
        margin-top: 22px;
        padding: 0 19px;
        box-sizing: border-box;
    }

    .button {
        width: 132px;
    }

    ::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
        border-radius: 22px;
    }

    ::-webkit-scrollbar {
        background: #2C2C2C;
        border-radius: 100px;
        height: 6px;
    }
}
