.accordion {
    width: 100%;
    background: #2C2C2C;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease 0s;
}

.accordionActive {
    transition: all 0.5s ease 0s;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
}

.accordionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.5s ease 0s;
    cursor: pointer;
}

.accordionContent {
    width: 500px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: rgba(34, 34, 33, 0.7);
    padding: 0 25px 18px;
    box-sizing: border-box;
    opacity: 1;
    transition: all 0.5s ease 0s;
}

.accordionContent p {
    margin: 0;
}

.accordionImg {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    color: #FFFFFF;
    margin: 22px 25px;
}

.titleActive {
    color: #222221;
}

@media screen and (max-width: 1400px) {
    .title {
        margin-right: 0;
    }

    .accordionContent {
        width: 228px;
    }

    .accordionImg  svg{
        height: 22px;
        width: 22px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 770px) {
    .title {
        font-size: 16px;
        line-height: 18px;
    }

    .accordionContent {
        width: 214px;
        font-size: 16px;
        line-height: 18px;
    }
}
