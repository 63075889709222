.floor {
    max-width: 1400px;
    width: 100%;
    height: 750px;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    background: url("../../../images/Shop/substrate.webp") no-repeat;
}

.floor__container {
    width: 100%;
    height: 100%;
    background: radial-gradient(66.73% 238.02% at 64.15% 83.93%, #40403F 0%, rgba(64, 64, 63, 0) 100%);
    border: 2px solid rgba(94, 94, 93, 0.3);
    border-radius: 11px;
    padding: 19px 30px 30px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.floors__container {
    max-width: 850px;
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
}

.box {
    width: 76px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    background: linear-gradient(180deg, rgba(155, 240, 243, 0.2) 0%, rgba(207, 112, 190, 0.2) 100%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.9) 99.99%, rgba(0, 0, 0, 0) 100%),
    conic-gradient(from 180deg at 48.5% 50%, #B8C9D3 -28.12deg, #FBFBFD 26.25deg, #C8D4DA 88.12deg, #FFFFFF 156.58deg, #AEC0CE 191.74deg, #E3E9EE 237.13deg, #FAFBFC 255.19deg, #D6DFE6 310.11deg, #B8C9D3 331.88deg, #FBFBFD 386.25deg);
    font-weight: 700;
    font-size: 20px;
    line-height: 17px;
    color: #D8D8D8;
    cursor: pointer;
}

.box__active {
    background: linear-gradient(180deg, rgba(217, 88, 179, 0.9) 0%, rgba(159, 231, 240, 0.9) 100%),
    conic-gradient(from 180deg at 48.5% 50%, #B8C9D3 -28.12deg, #FBFBFD 26.25deg, #C8D4DA 88.12deg, #FFFFFF 156.58deg, #AEC0CE 191.74deg, #E3E9EE 237.13deg, #FAFBFC 255.19deg, #D6DFE6 310.11deg, #B8C9D3 331.88deg, #FBFBFD 386.25deg);
    box-shadow: 0 0 10px #D365B9;
    border-radius: 19px;
    color: #181919;
    opacity: 1;
    transition: all 0.2s ease 0s;
}

.floorbutton_greenTexture {
    background: linear-gradient(180deg, rgba(222, 255, 197, 0.30) 0%, rgba(5, 255, 0, 0.30) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.90) 99.99%, rgba(0, 0, 0, 0.00) 100%), conic-gradient(from 180deg at 48.50% 50.00%, #FBFBFD 26.24999910593033deg, #C8D4DA 88.12500178813934deg, #FFF 156.58468008041382deg, #AEC0CE 191.74442768096924deg, #E3E9EE 237.1290135383606deg, #FAFBFC 255.19062280654907deg, #D6DFE6 310.1085305213928deg, #B8C9D3 331.875deg);
}
.floorbutton_yellowTexture {
    background: linear-gradient(180deg, rgba(255, 248, 80, 0.30) 0%, rgba(255, 245, 0, 0.30) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.90) 99.99%, rgba(0, 0, 0, 0.00) 100%), conic-gradient(from 180deg at 48.50% 50.00%, #FBFBFD 26.24999910593033deg, #C8D4DA 88.12500178813934deg, #FFF 156.58468008041382deg, #AEC0CE 191.74442768096924deg, #E3E9EE 237.1290135383606deg, #FAFBFC 255.19062280654907deg, #D6DFE6 310.1085305213928deg, #B8C9D3 331.875deg);
}
.floorbutton_brownTexture {
    background: linear-gradient(180deg, rgba(255, 232, 197, 0.30) 0%, rgba(255, 168, 0, 0.30) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.90) 99.99%, rgba(0, 0, 0, 0.00) 100%), conic-gradient(from 180deg at 48.50% 50.00%, #FBFBFD 26.24999910593033deg, #C8D4DA 88.12500178813934deg, #FFF 156.58468008041382deg, #AEC0CE 191.74442768096924deg, #E3E9EE 237.1290135383606deg, #FAFBFC 255.19062280654907deg, #D6DFE6 310.1085305213928deg, #B8C9D3 331.875deg);
}

.floorbutton_redTexture {
    background: linear-gradient(180deg, rgba(255, 197, 197, 0.30) 0%, rgba(255, 0, 0, 0.30) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.90) 99.99%, rgba(0, 0, 0, 0.00) 100%), conic-gradient(from 180deg at 48.50% 50.00%, #FBFBFD 26.24999910593033deg, #C8D4DA 88.12500178813934deg, #FFF 156.58468008041382deg, #AEC0CE 191.74442768096924deg, #E3E9EE 237.1290135383606deg, #FAFBFC 255.19062280654907deg, #D6DFE6 310.1085305213928deg, #B8C9D3 331.875deg);
}

.text {
    width: 86px;
    font-style: normal;
    font-weight: 700;
    font-size: 72px;
    line-height: 87px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.3;
    margin: 0;
}

.text:first-of-type {
    max-height: 86px;
    margin: 240px 0 0 20px;
    transform: rotate(-90deg);
}

@media screen and (max-width: 1600px) {
    .floor {
        background: url("../../../images/Shop/substrate__tablet.webp") no-repeat center;
        width: 661px;
        height: 355px;
        padding: 9px;
    }

    .floor__container {
        padding: 11px 20px 11px 0;
    }

    .floors__container {
        width: 410px;
        gap: 5px;
    }

    .box {
        width: 36px;
        height: 27px;
        border-radius: 6px;
        font-size: 10px;
        line-height: 8px;
    }

    .text {
        width: 30px;
        font-size: 34px;
        line-height: 41px;
    }

    .text:first-of-type {
        margin: 110px 20px 0;
    }
}
@media screen and (max-width: 768px) {
    .floor {
        width: 460px;
        border-radius: 20px;
    }

    .floor__container {
        padding-right: 0;
        width: 435px;
        justify-content: start;

    }

    .floors__container {
        justify-content: center;
        width: 435px;
    }

    .text {
        display: none;
    }
}

