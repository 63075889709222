.switch {
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border-radius: 34px;
}

.switchContainer {
    position: relative;
    display: inline-block;
    width: 142px;
    height: 100%;
    border-radius: 34px;
}

.text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-right: 20px;
}

.switchInput {
    display: none;
}

.switchLabel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    border-radius: 34px;
    background: #272727;
    transition: background-color 0.2s ease-in-out;
}

.switchInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 71px;
    height: 43px;
    border-radius: 89px;
    background: linear-gradient(89.35deg, #9BF0F3 0.56%, #DA53B1 102.39%);
    transition: margin-left 0.2s ease-in-out;
}

.sectorA {
    position: absolute;
    top: 14px;
    left: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    text-transform: uppercase;
}

.sectorB {
    position: absolute;
    top: 14px;
    right: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #9BF0F3;
    text-transform: uppercase;
    z-index: 0;
}

.colorA {
    color: #9BF0F3;
}

.colorB {
    color: #000000;
}

.switchInput:checked + .switchLabel .switchInner {
    margin-left: 70px;
}

.switchInput:checked + .switchLabel .switchSwitch {
    transform: translateX(26px);
}


@media screen and (max-width: 1600px) {
    .switch {
        height: 20px;
    }

    .switchContainer {
        width: 68px;
    }

    .text {
        margin-right: 8px;
        font-size: 8px;
        line-height: 9px;
    }

    .switchInner {
        width: 34px;
        height: 100%;
        border-radius: 42px;
    }

    .switchInput:checked + .switchLabel .switchInner {
        margin-left: 34px;
    }

    .sectorA {
        top: 6px;
        left: 14px;
        font-size: 8px;
        line-height: 8px;
    }

    .sectorB {
        top: 6px;
        right: 14px;
        font-size: 8px;
        line-height: 8px;
    }
}
