.container {
    width: 100%;
    padding: 77px 100px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border-style: solid;
    border-width: 0 0 2px;
    border-image: linear-gradient(to right, #000000, rgba(255, 255, 255, 0.56) 50%, #000000) 1;
}

.gallery_box {
    max-width: 1400px;
    width: 100%;
    background: #222221;
    border: 1px solid #5E5E5D;
    border-radius: 22px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    position: relative;
}

.mySwiper2 {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1300px;
    width: 100%;
    max-height: 550px;
    height: 100%;
}

.mySwiper2_img {
    max-width: 1300px;
    width: 100%;
    max-height: 530px;
    height: 100%;
    object-fit: cover;
    border-radius: 22px;
}

.mySwiper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1300px;
    width: 100%;
    max-height: 230px;
    height: 100%;
}

.mySwiper_img {
    max-width: 420px;
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-radius: 22px;
    cursor: pointer;
    opacity: 0.4;
    outline: none;
}

.nextSlideButton,
.prevSlideButton {
    max-width: 60px;
    background: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    top: 30%;
    z-index: 2;
}

.nextSlideButton {
    right: -80px;
}

.prevSlideButton {
    left: -80px;
    transform: rotate(180deg);
}

.container_circle {
    display: none;
}

.circle {
    width: 12px;
    height: 12px;
    background: linear-gradient(89.35deg, #9BF0F3 0.56%, #DA53B1 102.39%);
    border-radius: 50%;
    opacity: 0.5;
}

.active {
    opacity: 1;
}

@media screen and (max-width: 1300px) {
    .mySwiper2 {
        max-height: 450px;
    }

    .mySwiper2_img {
        max-height: 430px;
    }

    .mySwiper {
        max-height: 180px;
    }

    .mySwiper_img {
        height: 180px;
    }

    .nextSlideButton,
    .prevSlideButton {
        max-width: 50px;
    }
}

@media screen and (max-width: 1000px) {
    .container {
        padding: 47px 20px 60px;
    }

    .nextSlideButton,
    .prevSlideButton {
        display: none;
    }

    .container_circle {
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding: 40px 20px 50px;
    }

    .gallery_box {
        margin-top: 40px;
        gap: 20px;
    }

    .mySwiper2 {
        max-height: 370px;
    }

    .mySwiper2_img {
        max-height: 350px;
    }

    .mySwiper {
        max-height: 110px;
    }

    .mySwiper_img {
        height: 110px;
    }
}

@media screen and (max-width: 550px) {
    .container {
        padding: 30px 10px 40px;
    }

    .container_circle {
        margin-top: 14px;
    }

    .gallery_box {
        margin-top: 20px;
        gap: 8px;
        padding: 14px;
    }

    .mySwiper2 {
        max-height: 175px;
    }

    .mySwiper2_img {
        max-height: 165px;
        border-radius: 10px;
    }

    .mySwiper {
        max-height: 50px;
    }

    .mySwiper_img {
        height: 50px;
        border-radius: 10px;
    }
}

