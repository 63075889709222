.apartment {  max-width: 1400px;
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.left__box {
  max-width: 402px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right__box {
  max-width: 965px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left__box_top {
  max-width: 402px;
  width: 100%;
  height: 519px;
  padding: 44px 0 53px 37px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #222221;
  border: 1px solid #5e5e5d;
  border-radius: 19px;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 0 12px;
}

.info {
  max-width: 353px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  text-transform: uppercase;
  background: linear-gradient(89.35deg, #9bf0f3 0.56%, #da53b1 102.39%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 15px;
}

.freely__box {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 31px;
}

.freely {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 135px;
  height: 40px;
  background: #9bf0f3;
  border: 1px solid #9bf0f3;
  border-radius: 17px;
  font-size: 15px;
  line-height: 15px;
  text-transform: uppercase;
  color: #000000;
  margin: 0 10px;
}

.border {
  max-width: 30px;
  width: 100%;
  height: 2px;
  border: solid #5e5e5d;
  border-width: 1px 0 0;
  margin-left: -37px;
}

.border2 {
  width: 100%;
  max-width: 255px;
  height: 2px;
  border: solid #5e5e5d;
  border-width: 1px 0 0;
}

.text__box {
  max-width: 320px;
  width: 100%;
  margin-bottom: 32px;
}

.subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 0 13px;
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
}

.button__view {
  width: 100%;
  max-width: 330px;
  height: 62px;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  background: rgba(0, 0, 0, 0);
  position: relative;
  border-radius: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 19px;
  box-sizing: border-box;
  transition: all 0.6s ease;
  text-decoration: none;
}

.view {
  max-width: 38px;
  width: 100%;
  height: 36px;
}

.button__view:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: solid #9eeaf1;
  border-width: 2px 2px 0 0;
  border-radius: 22px;
}

.button__view:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: solid #d65eb6;
  border-width: 0 0 2px 2px;
  border-radius: 22px;
}

.button__view:hover {
  transition: all 0.6s ease;
  border-radius: 22px;
  box-shadow: 0 4px 16px rgba(218, 83, 177, 0.89);
}

.price__box {
  max-width: 402px;
  width: 100%;
  background: #222221;
  border: 1px solid #5e5e5d;
  border-radius: 19px;
  padding: 5px 27px 5px 33px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonWallet {
  width: 330px;
  height: 74px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #222221;
  border-radius: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #9bf0f3 0%, #da53b1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  position: relative;
}

.buttonWallet:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: solid #9eeaf1;
  border-width: 2px 2px 0 0;
  border-radius: 22px;
}

.buttonWallet:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: solid #d65eb6;
  border-width: 0 0 2px 2px;
  border-radius: 22px;
}

.buttonWallet:hover {
  transition: all 0.6s ease;
  box-shadow: 0 4px 16px rgba(218, 83, 177, 0.89);
}

.img__wallet {
  width: 22px;
  height: 22px;
}

.button__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.aprove__button {
  width: 337px;
  height: 62px;
  background: linear-gradient(
      180deg,
      rgba(222, 255, 197, 0.8) 0%,
      rgba(5, 255, 0, 0.8) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.9) 99.99%, rgba(0, 0, 0, 0) 100%),
    conic-gradient(
      from 180deg at 48.5% 50%,
      #b8c9d3 -28.12deg,
      #fbfbfd 26.25deg,
      #c8d4da 88.12deg,
      #ffffff 156.58deg,
      #aec0ce 191.74deg,
      #e3e9ee 237.13deg,
      #fafbfc 255.19deg,
      #d6dfe6 310.11deg,
      #b8c9d3 331.88deg,
      #fbfbfd 386.25deg
    );
  border-radius: 18px;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aprove__button_none {
  display: none;
}

.aprove__button:hover {
  transition: all 0.6s ease;
  box-shadow: 0 4px 16px rgba(92, 218, 83, 0.89);
}

.price__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 337px;
  height: 62px;
  background: linear-gradient(180deg, #9bf0f3 0%, #da53b1 100%);
  border-radius: 18px;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 22px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: all 0.6s ease;
}

.icon {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.disabled {
  color: #989898;
  cursor: auto;
  background: #464645;
}

.price__button:hover {
  transition: all 0.6s ease;
  box-shadow: 0 4px 16px rgba(218, 83, 177, 0.89);
}

.button__box {
  gap: 4px;
}

.disabled:hover {
  transition: all 0.6s ease;
  box-shadow: none;
}

.price__top_box {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price__title {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  color: #ffffff;
  margin: 0;
}

.container {
  display: flex;
  align-items: center;
}

.price__price {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  background: linear-gradient(180deg, #9bf0f3 0%, #da53b1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-right: 8px;
}

.price__text {
  max-width: 53px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}

.price__text_null {
  display: none;
}

.right_box {
  width: 100%;
  max-width: 961px;
  height: 100%;
  background: #222221;
  border: 1px solid #5e5e5d;
  border-radius: 18px;
  margin-left: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.first_box {
  margin: 20px 0 0 0;
  width: 80%;
  border-radius: 18px;
  display: flex;
  justify-content: center;
}

.img__box {
  height: 330px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.img__box img {
  max-height: 330px;
  object-fit: fill;
  padding: 0 3px;
  box-sizing: border-box;
}
.swiper_box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper_box li {
  cursor: default;
}

.swiper_box li button {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  margin-top: -30px;
  position: relative;
}

.swiper_box li button:before {
  content: "";
  color: rgba(0, 0, 0, 0);
}

.swiper_box li button {
  background: linear-gradient(89.35deg, #9bf0f3 0.56%, #da53b1 102.39%);
}

.button {
  border: none;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.right__box__title {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 50px 0 20px 0;
}

.second_box{
    width: 93%;
    display: flex;
    gap: 10px;
}

.content_box {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.content_subBox {
    display: flex;
    gap: 5px;
}

.content_subBox p{
    color: rgba(255, 255, 255, 0.60);
    font-family: 'Gilroy', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.5px; /* 128.125% */
    margin: 0;
}


@media screen and (max-width: 1600px) {
  .apartment {
    max-width: 710px;
    max-height: 406px;
  }
  
  .left__box_top {
    max-width: 190px;
    height: 246px;
    padding: 21px 20px 17px;
  }

  .title {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
  }

  .info {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 8px;
  }

  .freely__box {
    margin-bottom: 15px;
  }

  .border {
    width: 14px;
    margin-left: -20px;
  }

  .border2 {
    width: 105px;
    margin-right: -20px;
  }

  .freely {
    width: 64px;
    height: 19px;
    font-size: 7px;
    line-height: 7px;
    margin: 0 5px;
  }

  .text__box {
    width: 100%;
    max-width: 156px;
    margin-bottom: 15px;
  }

  .subtitle {
    font-size: 7px;
    line-height: 9px;
    margin-bottom: 6px;
  }

  .text {
    font-size: 8px;
    line-height: 11px;
  }

  .button__view {
    width: 156px;
    height: 30px;
    font-size: 12px;
    line-height: 10px;
    border-radius: 8px;
    padding: 0 14px;
  }

  .view {
    width: 18px;
    height: 16px;
  }

  .price__box {
    width: 190px;
    height: 105px;
    padding: 4px 13px 0px 16px;
  }

  .price__top_box {
    margin-bottom: 4px;
  }

  .price__title {
    font-size: 10px;
    line-height: 13px;
  }

  .price__price {
    margin-right: 4px;
    font-size: 10px;
    line-height: 13px;
  }

  .price__text {
    width: 26px;
    font-size: 5px;
    line-height: 6px;
  }

  .price__button {
    width: 160px;
    height: 26px;
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 2px;
  }

  .buttonWallet {
    margin-top: 10px;
    width: 160px;
    height: 26px;
    font-size: 10px;
    line-height: 10px;
  }

  .aprove__button {
    width: 160px;
    height: 26px;
    font-size: 10px;
    line-height: 10px;
  }

  .img__wallet {
    width: 14px;
    height: 14px;
  }

  .icon {
    width: 13px;
    height: 13px;
    margin-right: 5px;
  }

  .button {
    padding: 0;

    width: 14px;
    height: 14px;
  }


  .right_box {
    margin-left: 10px;
    max-width: 511px;
  }

  .first_box {
    margin: 5px 0 0 0;
    width: 80%;
    border-radius: 18px;
    display: flex;
    justify-content: center;
  }
  
  .img__box {
    height: 150px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  
  .img__box img {
    max-height: 150px;
    object-fit: fill;
    padding: 0 3px;
    box-sizing: border-box;
  }

  .swiper_box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper_box li {
    top: 35px;
  }

  .right__box__title {
    color: #fff;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 30px 0 5px 0;
  }
  
  .second_box{
      width: 93%;
      display: flex;
      gap: 10px;
  }
  
  .content_box {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 10px;
  }
  .content_subBox {
      display: flex;
      gap: 5px;
  }
  .content_subBox img {
    width: 15px;
    height: 15px;
  }
  
  .content_subBox p{
      color: rgba(255, 255, 255, 0.60);
      font-family: 'Gilroy', sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 12.5px; /* 128.125% */
      margin: 0;
  }
  .swiper_box li button {
    width: 6px;
    height: 6px;
  }
}

@media screen and (max-width: 768px) {
  .text__box {
    height: 71px;
  }

  .container {
    height: 14px;
  }
}
