.app {
    display: flex;
    flex-direction: column;
}

.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
