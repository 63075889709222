.accordion {
    width: 100%;
    min-height: 80px;
    background: #191919;
    border-radius: 3px;
}

.title {
    width: 477px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin: 0;
}

.accordionHeader {
    padding: 16px 16px 19px 22px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.open,
.close{
    width: 25px;
    height: 25px;
    background: url("../../../images/AboutUs/AboutUs__button.svg") no-repeat center;
    transform: rotate(-90deg);
    transition: all 0.3s ease 0s;
}

.open {
    transform: rotate(90deg);
}

.accordionContent {
    padding: 0 21px 22px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 0.6;
}

.accordionContent p {
    margin: 0;
}

.accordionContent a {
    text-decoration: none;
}

@media screen and (max-width: 1300px) {
    .title {
        width: 330px;
    }

    .accordionContent {
        font-size: 16px;
        padding-bottom: 12px;
    }
}

@media screen and (max-width: 1000px) {
    .title {
        width: 236px;
    }
}

@media screen and (max-width: 770px) {
    .title {
        width: 222px;
        font-size: 16px;
    }

    .accordionContent {
        font-size: 14px;
        padding-bottom: 8px;
    }
}

