.roadmap {
    width: 100%;
    height: 100%;
    padding: 92px 0 175px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("../../images/banner/banner__logo.webp") no-repeat bottom right;
    border-style: solid;
    border-width: 0 0 2px;
    position: relative;
    border-image: linear-gradient(to right, #000000, rgba(255, 255, 255, 0.56) 50%, #000000) 1;
}

.roadmap:after {
    content: '';
    width: 100%;
    margin-top: -180px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: url("../../images/Roadmap/roamap__background.svg") no-repeat top right;
    opacity: 0.4;
}

.roadmap__container {
    max-width: 1247px;
    width: 100%;
    margin-top: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.roadmap__center {
    margin-left: 120px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.roadmap__img {
    max-width: 100%;
}

.roadmap__left {
    height: 1205px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 110px;
    left: 34px;
    margin: 0;
    padding: 0;
}

.roadmap__right {
    position: absolute;
    top: 295px;
    right: -90px;
    height: 860px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.container__right {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.text__box {
    display: flex;
    flex-direction: column;
}

.roadmap__text {
    max-width: 230px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
}

.roadmap__span {
    max-width: 310px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    position: relative;
    padding-left: 35px;
    box-sizing: border-box;
}

.roadmap__span:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: url("../../images/Roadmap/icon.svg") no-repeat left center;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 45px;
}

.box__right {
    margin-left: 0;
    margin-right: 45px;
    flex-direction: row-reverse;
}

.circle {
    width: 22px;
    height: 22px;
    border: 2px solid #DA53B1;
    border-radius: 50%;
    position: relative;
}

.circle:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
}

.line {
    margin-right: 22px;
    margin-left: 11px;
    height: 2px;
    width: 44px;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
}

.line__right {
    margin-left: 22px;
    margin-right: 11px;
}

.img__box {
    width: 170px;
    height: 155px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(155, 240, 243, 0.2) 0%, rgba(207, 112, 190, 0.2) 100%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.9) 99.99%, rgba(0, 0, 0, 0) 100%),
    conic-gradient(from 180deg at 48.5% 50%, #B8C9D3 -28.12deg, #FBFBFD 26.25deg, #C8D4DA 88.12deg, #FFFFFF 156.58deg, #AEC0CE 191.74deg, #E3E9EE 237.13deg, #FAFBFC 255.19deg, #D6DFE6 310.11deg, #B8C9D3 331.88deg, #FBFBFD 386.25deg);
    border-radius: 50%;
    border: 2px solid #B8C9D3;
    overflow: hidden;
}

.img {
    max-width: 62px;
}

.img__logo {
    width: 130px;
}

.roadmap__img__320px {
    display: none;
}

@media screen and (max-width: 1400px) {
    .roadmap {
        padding: 52px 0 175px;
        background: none;
    }

    .roadmap:after {
        margin-top: -400px;
    }

    .roadmap__container {
        max-width: 680px;
        width: 100%;
        margin-top: 46px;
    }

    .roadmap__center {
        margin-left: 0;
    }

    .roadmap__img {
        height: 1055px;
    }

    .roadmap__left {
        height: 1050px;
        top: 45px;
        left: -44px;
        justify-content: flex-start;
    }

    .roadmap__left li:nth-child(1) {
        margin-bottom: 80px;
    }

    .roadmap__left li:nth-child(2) {
        margin-bottom: 150px;
    }

    .roadmap__left li:nth-child(3) {
        margin-bottom: 170px;
    }

    .roadmap__right {
        top: 255px;
        left: 335px;
        height: 420px;
    }

    .container__right_tablet {
        position: absolute;
        bottom: -332px;
        left: 0;
    }

    .roadmap__text {
        max-width: 150px;
        font-size: 16px;
        line-height: 20px;
    }

    .roadmap__span {
        max-width: 160px;
        margin-top: 8px;
        font-size: 14px;
        line-height: 17px;
        padding-left: 35px;
    }

    .box {
        margin-left: 10px;
    }

    .box__right {
        margin-left: 0;
        margin-right: 10px;
    }

    .line {
        margin-right: 10px;
        margin-left: 10px;
    }

    .line__right {
        margin-left: 10px;
        margin-right: 10px;
    }

    .img__box {
        width: 120px;
        height: 110px;
        overflow: hidden;
    }

    .img__logo {
        width: 90px;
    }
}

@media screen and (max-width: 770px) {
    .roadmap {
        padding: 26px 0 30px;
    }

    .roadmap:after {
        display: none;
    }

    .roadmap__container {
        max-width: 290px;
        width: 100%;
        margin-top: 26px;
        justify-content: flex-start;
        margin-left: 30px;
    }

    .roadmap__img {
        display: none;
    }

    .roadmap__img__320px {
        display: block;
        height: 810px;
    }

    .box {
       display: none;
    }

    .roadmap__left {
        justify-content: space-between;
        height: 765px;
        top: 0;
        left: -17px;
    }

    .roadmap__left li:nth-child(1) {
        margin-bottom: 0;
    }

    .roadmap__left li:nth-child(2) {
        margin-bottom: 0;
    }

    .roadmap__left li:nth-child(3) {
        margin-bottom: 0;
    }

    .roadmap__right {
        height: 318px;
        top: 192px;
        left: -17px;
    }

    .container__right_tablet {
        bottom: -220px;
    }

    .container {
        flex-direction: row-reverse;
        align-items: flex-start;
    }

    .container__right {
        align-items: flex-start;
    }

    .img__box {
        width: 68px;
        height: 62px;
        overflow: hidden;
        margin-right: 19px;
    }

    .img {
        width: 30px;
    }

    .img__logo {
        width: 48px;
    }

    .roadmap__text {
        margin-top: 15px;
        max-width: 197px;
        font-size: 12px;
        line-height: 15px;
    }

    .roadmap__span {
        max-width: 200px;
        margin-top: 4px;
        font-size: 12px;
        line-height: 15px;
        padding-left: 30px;
    }

}
