.logo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo__img {
    width: 64px;
    height: 54px;
    margin-bottom: 21px;
}

.logo__title {
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0;
}

@media screen and (max-width: 1200px) {
    .logo__title {
        font-size: 30px;
        line-height: 36px;
    }
}

@media screen and (max-width: 768px) {
    .logo__img {
        margin-bottom: 8px;
    }

    .logo__title {
        font-size: 22px;
        line-height: 27px;
    }
}

