.footer {
    width: 100%;
    padding: 91px 20px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer__container {
    max-width: 1400px;
    width: 100%;
}

.nav__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 0;
}

.logo__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img__MRT {
    max-width: 303px;
    height: 90px;
    margin-bottom: 22px;
}

.img__MetaRT {
    max-width: 304px;
    height: 20px;
}

.nav__box {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-style: solid;
    border-width: 1px 0;
    border-image: linear-gradient(to right, #000000, rgba(255, 255, 255, 0.56) 50%, #000000) 1;
    padding-right: 60px;
    padding-left: 20px;
    box-sizing: border-box;
}

.nav__box:last-of-type {
    padding-right: 20px;
    padding-left: 60px;
}

.bottom__container {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 49px;
}

.social__container {
    max-width: 440px;
    width: 100%;
    margin-left: -150px;
}

.footer__text {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 122%;
    color: #FFFFFF;
    opacity: 0.6;
    margin: 0;
}

@media screen and (max-width: 1200px) {
    .footer {
        padding: 50px 50px;
    }

    .footer__text {
        max-width: 144px;
        font-size: 16px;
    }

    .social__container {
        max-width: 260px;
        margin-left: -40px;
    }

    .bottom__container {
        margin-top: 21px;
    }

    .nav__box {
        max-width: 224px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .nav__box:last-of-type {
        padding-right: 20px;
        padding-left: 20px;
    }

    .img__MRT {
        height: 39px;
        max-width: 128px;
        margin-bottom: 6px;
    }

    .img__MetaRT {
        height: 9px;
        max-width: 128px;
    }
}

@media screen and (max-width: 768px) {
    .footer {
        padding: 30px 10px 10px;
    }

    .footer__container {
        max-width: 289px;
    }

    .nav__box {
        flex-direction: column;
        justify-content: center;
        align-items: start;
        height: 83px;
        gap: 12px;
        padding-right: 0;
        padding-left: 0;
        border-image: linear-gradient(to right, rgba(255, 255, 255, 0.56) 100%, #000000) 1;
    }

    .logo__container {
        margin: 0 -10px;
    }

    .nav__box:last-of-type {
        align-items: flex-end;
        padding-right: 0;
        padding-left: 0;
    }

    .bottom__container {
        height: 100px;
        display: grid;
        justify-items: center;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(3, 1fr);
        margin-top: 0;
    }

    .social__container {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-column-end: 4;
        margin: 0;
    }

    .footer__text {
        height: 40px;
        font-size: 14px;
        line-height: 17px;
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 3;
        margin-left: -50px;
    }

    .footer__text:last-of-type {
        width: 52px;
        grid-row-start: 2;
        grid-column-start: 3;
        margin-left: 0;
        margin-right: -50px;
    }
}

