.container {
    width: 100%;
    padding: 77px 20px 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border-style: solid;
    border-width: 0 0 2px;
    border-image: linear-gradient(to right, #000000, rgba(255, 255, 255, 0.56) 50%, #000000) 1;
}

.box {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
    justify-items: center;
    gap: 30px;
    position: relative;
}

.center_box {
    width: 275px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -120px;
    left: 50%;
    -webkit-transform: translate(-50%,0);
    -ms-transform: translate(-50%,0);
    transform: translate(-50%,0);
}

.logo_box {
    width: 274px;
    height: 323px;
    background: url("../../images/AccessFeatures/background-image.webp") center no-repeat;
    margin-top: 62px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
}

.logo_img {
    max-width: 100%;
    margin-bottom: 50px;
}

.cards_box {
    display: flex;
    flex-direction: column;
    max-width: 475px;
    width: 100%;
    gap: 30px;
}

@media screen and (max-width: 1340px) {
    .container {
        padding: 50px 20px;
    }

    .box {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(3, auto);
        gap: 20px;
    }

    .cards_box {
        grid-row-start: 2;
        grid-row-end: 2;
        gap: 20px;
    }

    .logo_box {
        width: 326px;
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 3;
        margin: 0;
    }

    .center_box {
        position: static;
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 3;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@media screen and (max-width: 700px) {
    .container {
        padding: 30px 14px;
    }

    .box {
        margin-top: 30px;
        grid-template-columns: repeat(1, auto);
        grid-template-rows: repeat(4, auto);
        gap: 20px;
    }

    .logo_box {
        width: 192px;
        height: 220px;
        background: url("../../images/AccessFeatures/background-image320.webp") center no-repeat;
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 1;
    }

    .logo_img {
        max-width: 90%;
        margin-bottom: 34px;
    }

    .center_box {
        grid-row-start: 4;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 1;
    }

    .cards_box {
        align-items: center;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 3;
        grid-row-end: 3;
    }

    .cards_box:nth-child(1) {
        align-items: center;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 2;
        grid-row-end: 2;
    }
}

