.container {
    width: 100%;
    padding: 77px 20px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border-style: solid;
    border-width: 0 0 2px;
    border-image: linear-gradient(to right, #000000, rgba(255, 255, 255, 0.56) 50%, #000000) 1;
}

.partners_box {
    max-width: 1400px;
    width: 100%;
    max-height: 255px;
    background: #222221;
    border: 1px solid #5E5E5D;
    border-radius: 22px;
    padding: 20px 115px;
    box-sizing: border-box;
    margin-top: 50px;
}

.card_box {
    height: 130px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.card {
    max-width: 90px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

.img {
    max-width: 100%;
    max-height: 100%;
}

@media screen and (max-width: 1400px) {
    .container {
        padding: 50px 20px;
    }

    .partners_box {
        margin-top: 30px;
        padding: 20px 40px;
    }

    .card_box {
        height: 100px;
    }

    .card {
        max-width: 80px;
    }
}

@media screen and (max-width: 600px) {
    .container {
        padding: 30px 14px;
    }

    .partners_box {
        margin-top: 30px;
        padding: 20px 28px;
    }

    .card_box {
        height: 70px;
    }

    .card {
        max-width: 60px;
    }
}

