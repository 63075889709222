.faq {
    width: 100%;
}

.faq__container {
    padding: 100px 0 114px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: solid;
    border-width: 0 0 2px;
    border-image: linear-gradient(to right, #000000, rgba(255, 255, 255, 0.56) 50%, #000000) 1;
}

.container {
    margin-top: 43px;
    display: flex;
    gap: 34px;
}

.box {
    width: 622px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

@media screen and (max-width: 1300px) {
    .faq__container {
        padding: 50px 0;
    }

    .box {
        width: 424px;
    }

    .container {
        gap: 20px;
    }
}

@media screen and (max-width: 1000px) {
    .box {
        width: 324px;
    }
}

@media screen and (max-width: 770px) {
    .faq__container {
        padding: 30px 0;
    }

    .container {
        margin-top: 17px;
        flex-direction: column;
    }

    .box {
        width: 290px;
    }
}

