.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 52px;
    height: 30px;
}

.switch_label {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #aaa;
}

.container input {
    display: none;
}

.switch_slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: .4s;
    cursor: pointer;
}

.switch_slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 2px;
    bottom: 2px;
    background: linear-gradient(180deg, #9BF0F3 0%, #DA53B1 100%);
    border-radius: 50%;
    transition: .4s;
}

input:checked + .switch_slider:before {
    transform: translateX(22px);
}

.left__box {
    right: 60px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    opacity: 1;
}

.right__box {
    left: 60px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    gap: 8px;
    opacity: 1;
}

.opacity {
    opacity: 0.5;
    transition: all 0.5s ease 0s;
}

.text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.img {
    width: 31px;
    height: 31px;
}

@media screen and (max-width: 1600px) {
    .container {
        width: 34px;
        height: 20px;
    }

    .left__box {
        right: 38px;
        gap: 5px;
    }

    .switch_slider:before {
        height: 17px;
        width: 20px;
        left: 2px;
        bottom: 1px;
        border-radius: 50%;

    }

    .right__box {
        left: 38px;
        gap: 5px;
    }

    .text {
        font-size: 10px;
        line-height: 10px;
    }

    .img {
        width: 18px;
        height: 18px;
    }
    input:checked + .switch_slider:before {
        transform: translateX(11px);
    }
}
