.banner {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 180px 0 0;
    box-sizing: border-box;
    position: relative;
}

.youtube_box {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1900px;
    height: 100%;
    z-index: -1;
    opacity: 0.3;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.youtube {
    width: 100%;
    height: 100%;
}

.youtube iframe{
    background-size: cover;
    width: 100%;
    height: 100%;
}

.banner::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../images/banner/Ellipse1.svg") no-repeat right bottom;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    z-index: -1;
}

.banner::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../images/banner/Ellipse2.svg") no-repeat left bottom;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
}

.banner__container {
    max-width: 1440px;
    width: 100%;
    display: flex;
    padding: 0 10px 90px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.social__container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.direction__column {
    max-height: 440px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    max-width: 55px;
    margin: 200px 0 0 -5vw;
}

.social {
    width: 50px;
    height: 50px;
    border: 1px solid #FFFFFF;
    border-radius: 90px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.social__text {
    font-family: 'Times New Roman', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #D9D9D9;
    margin: 0;
}

.info__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.top_bigTitle {
    margin: 0;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    color: #FFFFFF;
}

.top_title {
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 67px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.top_subtitle {
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    color: #FFFFFF;
}

.expirience {
    display: none;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 300px;
    text-align: center;
}

.top_box {
    max-width: 1380px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.top_boxInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.container_inner {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.img_inner {
    width: 62px;
    height: 58px;
}

.text_inner {
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 67px;
    text-align: center;
    color: #d1cdda;
}

.content_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #FFFFFF;
    text-transform: uppercase;
    margin: 32px 0 40px;
}

.banner__ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.ul_box {
    display: flex;
    height: 150px;
    max-width: 560px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    gap: 14px;
    margin: 0;
    padding: 0;
}

.banner__li {
    height: 26px;
    background: url("../../images/banner/banner_icon.svg") no-repeat left;
    padding-left: 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

.button__container {
    max-width: 550px;
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.button__learn {
    width: 264px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    border: none;
    font-size: 20px;
    line-height: 24px;
    color: #222221;
    background: linear-gradient(180deg, #9EEAF1 0%, #D65EB6 100%);
    border-radius: 22px;
    cursor: pointer;
    transition: box-shadow 0.5s ease;
    text-decoration: none;
}

.button__learn:hover {
    box-shadow: 0 4px 16px rgba(218, 83, 177, 0.89);
}

.button__text {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    background: linear-gradient(180deg, #9EEAF1 0%, #D65EB6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.button__mint {
    padding: 0;
    width: 264px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0);
    cursor: pointer;
    position: relative;
    border: solid #9EEAF1;
    border-width: 2px 2px 0 0;
    border-radius: 22px;
    transition: all 0.5s ease;
    text-decoration: none;
}

.button__mint::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: solid #D65EB6;
    border-width: 0 0 2px 2px;
    border-radius: 22px;
    transition: all 0.6s linear;
}

.button__mint:hover {
    transition: all 0.6s ease;
    box-shadow: 0 4px 16px rgba(218, 83, 177, 0.89);
}

.wave {
    width: 100%;
    margin-bottom: -20px;
}

.banner_text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 33px 0 49px;
}

.border {
    margin: 85px 0 260px;
    max-width: 900px;
    width: 100%;
}
@media (min-width: 1600px) {
    .youtube_box {
        width: 1700px;
        height: 110%;
    }
}
@media (max-width: 1650px) {
    .direction__column {
        max-height: 440px;
        flex-direction: column;
        justify-content: flex-start;
        gap: 15px;
        max-width: 55px;
        margin: 200px 0 0 0px;
    }
}
@media screen and (max-width: 1400px) {
    .banner {
        padding-bottom: 0;
        padding-top: 170px;
    }

    .info__container {
        max-width: 100%;
        align-items: flex-start;
        margin-left: 0;
        margin-top: 0;
    }
    .container__img {
        width: 100%;
        justify-content: center;
        margin-bottom: 63px;
    }

    .img__mrt {
        max-width: 276px;
        height: 84px;
        margin-bottom: 16px;
    }

    .img__MetaRT {
        max-width: 518px;
        height: 19px;
    }

    .banner__container {
        flex-direction: column-reverse;
        padding:0 20px 0;
        background: none;
        margin-bottom: 36px;
    }

    .social__container {
        flex-direction: row;
        max-width: 440px;
        justify-content: center;
    }

    .direction__column {
        height: 50px;
        margin: 60px 0;
    }

    .button__container {
       max-width: 100%;
        justify-content: center;
        gap: 24px;
    }

    .banner_text {
        max-width: 661px;
        text-align: center;
    }

    .wave {
        opacity: 0;
    }
}

@media screen and (max-width: 1200px) {
    .banner {
        padding-top: 150px;
    }

    .direction__footer {
        max-width: 260px;
        width: 100%;
        height: 28px;
    }

    .social__footer {
        height: 28px;
        width: 28px;
    }

    .svg__footer {
        max-width: 13px;
        max-height: 10px;
    }
}

@media screen and (max-width: 1000px) {
    .top_title {
        font-size: 40px;
    }

    .banner__ul {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 46px;
        box-sizing: border-box;
        gap: 14px;
    }

    .ul_box {
        height: 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 770px) {
    .top_bigTitle {
        font-size: 35px;
        line-height: 186%;
    }
    .top_boxInner {
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-bottom: 30px;
    }
    .top_title {
        font-size: 22px;
        line-height: 186%;
    }
    .top_subtitle {
        font-size: 17px;
        margin-left: 10px;
    }
    .button__container {
        gap: 20px;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .border {
        margin: 45px 0;
    }
    .expirience {
        display: block;
    }
    .img_inner {
        width: 27px;
        height: 25px;
    }

    .text_inner {
        font-size: 25px;
        line-height: 30px;
    }

    .banner_text {
        font-size: 12px;
        line-height: 15px;
        margin: 9px 0 20px;
        padding: 0 10px;
        box-sizing: border-box;
    }

    .banner {
        padding-top: 120px;
        justify-content: center;
        background: url("../../images/banner/banner__logo-320.webp") no-repeat right;
    }

    .social__svg {
        max-width: 13px;
        max-height: 10px;
    }

    .social {
        width: 28px;
        height: 28px;
    }

    .info__container {
        align-items: center;
        margin-bottom: 45px;
    }

    .social__container {
        gap: 15px;
        max-width: 360px;
        justify-content: center;
        height: 28px;
        margin-top: 0;
    }

    .banner__container {
        padding: 0;
        margin-bottom: 30px;
        align-items: center;
    }

    .img__mrt {
        max-width: 143px;
        height: 43px;
        margin-bottom: 9px;
    }

    .banner::before {
        display: none;
    }

    .banner::after {
       display: none;
    }

    .banner__container::after {
        background: none;
    }

    .img__MetaRT {
        max-width: 271px;
        height: 9px;
    }

    .container__img {
        width: 100%;
        margin-bottom: 26px;
    }

    .title {
        font-size: 12px;
        line-height: 15px;
        margin: 20px 0 20px;
    }

    .banner__li {
        font-size: 12px;
        line-height: 15px;
    }
}

@media (max-width: 400px) {
    .expirience {
        margin-bottom: 330px;
    }
    .top_bigTitle {
        font-size: 22px;
        line-height: 140%;
    }
    .top_title, .top_subtitle  {
        font-size: 12px;
        line-height: normal;
    }
    .top_boxInner {
        margin-bottom: 5px;
    }
    .text_inner {
        font-size: 18px;
    }
    .border {
        margin: 20px 0;
    }
    .expirience {
        width: 240px;
        font-size: 12px;
    }
}   